































































































































import { Component, Vue } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    ConnectMetamask: () => import("@/components/wallet/ConnectMetamask.vue"),
    WalletDialog: () => import("@/components/wallet/WalletDialog.vue"),
  },
})
export default class Staking extends Vue {
  wallet = walletStore;
  chainId = process.env.VUE_APP_CHAIN_ID;
  openLink(url) {
    window.open(url, "_blank");
  }
}
